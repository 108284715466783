import React, { Component } from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import HomeIcon from "@material-ui/icons/Home";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { setRoles } from "../../utility/user-actions";
import { redirectRoot } from "../../utility/navigate";
import { PublicClientApplication} from "@azure/msal-browser";
import { Cookies } from "react-cookie";

import axios from "../../api/axios-allure-docker";
import wse from "../../assets/images/wselogo.png";

const cookies = new Cookies();

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
  },
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link
        color="inherit"
        href="https://www.wallstreetenglish.com/"
      >
        WSE Limited All rights reserved
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: theme.palette.secondary.main,
  },
});

class AllureDockerSignIn extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      error: false,
      errorMessage: "",
      userTextField: {
        value: "",
      },
      passTextField: {
        value: "",
        show: false,
      },
    };
  }

  componentDidMount() {this.checkAzureLogin()}

  signin = (event) => {
    event.preventDefault();
    axios
      .post("/login", {
        username: this.state.userTextField.value,
        password: this.state.passTextField.value,
      })
      .then((response) => {
        const expiresIn = response.data.data.expires_in;
        if (expiresIn !== 0) {
          const expirationDate = new Date(
            new Date().getTime() + expiresIn * 1000
          );
          localStorage.setItem("expirationDate", expirationDate);
        }
        setRoles(response.data.data.roles);
        this.props.isLoginRequired();
        this.props.history.replace("/");
      })
      .catch((error) => {
        this.showError(error.message);
      });
  };

  ssosignin = async () => {
      axios
      .post("/login", {
        username: "wseuser",
        password: "Pass@123",
      })
      .then((response) => {
        const expiresIn = response.data.data.expires_in;
        if (expiresIn !== 0) {
          const expirationDate = new Date(
            new Date().getTime() + expiresIn * 1000
          );
          localStorage.setItem("expirationDate", expirationDate);
        }
        setRoles(response.data.data.roles);
        this.props.isLoginRequired();
        this.props.history.replace("/");
      })
      .catch((error) => {
        this.showError(error.message);
      });
  };

  handleUserTextFieldChange = (event) => {
    const userTextField = { ...this.state.userTextField };
    userTextField.value = event.target.value;
    this.setState({ userTextField: userTextField });
  };

  handlePassTextFieldChange = (event) => {
    const passTextField = { ...this.state.passTextField };
    passTextField.value = event.target.value;
    this.setState({ passTextField: passTextField });
  };

  handleClickShowPassword = () => {
    const passTextField = { ...this.state.passTextField };
    passTextField.show = !this.state.passTextField.show;
    this.setState({ passTextField: passTextField });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  showError = (errorMessage) => {
    this.setState({ error: true, errorMessage });
  };

  resetStates = () => {
    this.setState(this.initialState);
  };

  goToHome = () => {
    redirectRoot();
  };

  checkAzureLogin = async () => {
    try {
      if (cookies.get("msal.interaction.status")) {
        if (cookies.get("msal.interaction.status") === process.env.REACT_APP_AZURE_CLIENT_ID) { 
            this.ssosignin();
          }
        }
      }catch (error) {
      console.error(error);
    }
  };

  handleAzureLogin = async () => {
    const loginRequest = {
      scopes: ["openid", "profile", "email", "User.Read"],
    };
  
    try {
      await msalInstance.initialize();
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0 || !cookies.get("msal.interaction.status")) {
        await msalInstance.loginRedirect(loginRequest);
      }
    } catch (error) {
      console.error(error);
      this.showError(error.message);
    }
  };
  render() {
    const { classes } = this.props;

    let isHomeAnOption;
    if (this.props.isHomeAnOption) {
      isHomeAnOption = (
        <IconButton color="inherit" onClick={this.goToHome}>
          <HomeIcon />
        </IconButton>
      );
    }

    return (
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper className={classes.paper} elevation={3}>
            <div align="center" className={classes.logoContainer}>
              <img src={wse} alt="wse" height="100" />
            </div>
            <Typography component="h1" variant="h5" align="center" className={classes.title}>
              WSE Quality Dashboard
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.azureButton}
              onClick={this.handleAzureLogin}
            >
              Click here to Sign In with WSE Account
            </Button>
            <form className={classes.form} noValidate onSubmit={this.signin}>
              <TextField
                error={this.state.userTextField.error}
                value={this.state.userTextField.value}
                onChange={this.handleUserTextFieldChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="user"
                label="Admin Username"
                name="user"
                autoComplete="user"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                error={this.state.passTextField.error}
                value={this.state.passTextField.value}
                type={this.state.passTextField.show ? "text" : "password"}
                onChange={this.handlePassTextFieldChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Admin Password"
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.passTextField.show ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText className={classes.error}>
                {this.state.errorMessage}
              </FormHelperText>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                Admin Sign In
              </Button>
            </form>
            {isHomeAnOption}
          </Paper>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    );
  }
}

export default withStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  logoContainer: {
    marginBottom: theme.spacing(2),
  },
  title: {
    color: "#003359",
    fontWeight: 800,
    fontSize: "32px",
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  azureButton: {
    margin: theme.spacing(3, 0, 2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: 'red',
  },
}))(
  withRouter(AllureDockerSignIn)
);
