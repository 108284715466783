import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import wse from "../../assets/images/wselogo.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    padding: theme.spacing(2, 3, 2, 3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    border: "1px solid #ccc",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f9f9f9",
  },
  title: {
    fontWeight: 800,
    fontSize: "32px",
    color: "#003359",
  },
  subtitle: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#003359",
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    fontSize: "16px",
    color: "#333",
  },
});

class AllureDockerHomePage extends Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div style={{ height: "100%" }} align="center">
                <img src={wse} alt="wse" />
                <Typography variant="h3" className={classes.title} align="center">
                  WSE Quality Dashboard
                </Typography>
                <div className={classes.content}>
                  <div className={classes.section}>
                    <Typography variant="h5" className={classes.subtitle}>
                      Overview
                    </Typography>
                    <Typography className={classes.paragraph} align="left">
                      The Quality Monitor Dashboard is built on the Allure Reporting Library, a powerful tool for storing and generating test reports. It provides clear, detailed, and visually engaging test reports that enhance collaboration, issue resolution, and overall project quality. The dashboard benefits team members, engineers, and stakeholders in monitoring test statuses across projects. It visualizes the complete test pyramid (Unit, API, and UI) suite results under one dashboard.
                    </Typography>
                  </div>
                  <div className={classes.section}>
                    <Typography variant="h5" className={classes.subtitle}>
                      Key Features
                    </Typography>
                    <Typography className={classes.paragraph} align="left">
                      <strong>1. Unified Test Results Visualization</strong>
                      <ul>
                        <li>
                          <strong>Integrated Projects:</strong> All test suites across projects are integrated under the Allure dashboard to visualize test results. Currently, the dashboard integrates test results from the NSE Platform API and UI suites.
                        </li>
                        <li>
                          <strong>Build-Based Visualization:</strong> Test results can be visualized based on specific build numbers, making it easy to track the outcomes of each build and analyze the results.
                        </li>
                      </ul>
                    </Typography>
                    <Typography className={classes.paragraph} align="left">
                      <strong>2. Historical Data Storage</strong>
                      <ul>
                        <li>
                          <strong>Execution History:</strong> The dashboard maintains the results history, allowing observation of trends and patterns over time.
                        </li>
                        <li>
                          <strong>Trend Analysis:</strong> By maintaining a history of test results, recurring issues can be identified, and the impact of changes on overall system stability can be assessed.
                        </li>
                      </ul>
                    </Typography>
                  </div>
                  <div className={classes.section}>
                    <Typography variant="h5" className={classes.subtitle}>
                      Navigation Steps
                    </Typography>
                    <Typography className={classes.paragraph} align="left">
                      <ol>
                        <li>
                          Click on the Three line menu on the top left in the dashboard to navigate to projects.
                        </li>
                        <li>
                          List of projects will be displayed, on clicking any one project we can visualize the test results of that project. As of now we have integrated API and UI suites of NSE core platform.
                        </li>
                        <li>
                          Click on any one of the project to get the reports relevant to that project.
                          <ol type="a">
                            <li>
                              Overview: This will display the overall cumulative suite reports like total cases, its pass/fail percentage, browser version and build number with trend graphs.
                            </li>
                            <li>
                              Categories: This will give you the information about the test case failure information as product defects and test defects. Products defects can be breaks in application and test defects are flakiness or environment issues.
                            </li>
                            <li>
                              Suites: This will give you overall suite execution information feature wise with history information on clicking test results.
                            </li>
                            <li>
                              Graphs: This will represent the suite results in pictorial graphs with status, severity, duration and trends.
                            </li>
                            <li>
                              On the header side, we have a menu to export the results in multiple formats. We have disabled the project configuration options in the menu as a viewer perspective.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AllureDockerHomePage);
